<script>
	import cn from 'classnames';

	import heureka from '$lib/images/home/heureka.png';
	import content_2 from '$lib/images/home/content_2.jpg';
	import content_3 from '$lib/images/home/content_3.jpg';
	import vyber_skutru from '$lib/images/home/vyber-skutru.gif';
	import BlogList from '../components/BlogList.svelte';
	import Heading from './(components)/Heading.svelte';
	import Star from '$lib/images/svg/star.svelte';
	import OurAdvantages from './(components)/OurAdvantages.svelte';

	export let data;
</script>

<svelte:head>
	<title>Scootland - skútry, scooter tuning, servis, náhradní díly</title>
	<meta name="description" content="Obchod, servis a eshop na náhradní díly, úpravy skútrů a scooter tuning. Doplňky, návody pro značky jako Honda, Piaggio 125ccm a další. S námi to valí! "/>
	<meta name="keywords" content="Scootland, tuning skútru, scooter, kontakt, telefon, email, firma, Brno"/>
	<link rel="canonical" href={'https://www.scootland.cz'} />
	<meta property="og:title" content="Scootland - skútry, scooter tuning, servis, náhradní díly" />
	<meta property="og:description" content="Obchod, servis a eshop na náhradní díly, úpravy skútrů a scooter tuning. Doplňky, návody pro značky jako Honda, Piaggio 125ccm a další. S námi to valí! "/>
	<meta property="og:image" content="/image/og-hp.webp"/>
	<meta property="og:url" content="https://www.scootland.cz"/>
	<meta property="og:suite_name" content="Scootland"/>
	<meta property="og:type" content="website" />
</svelte:head>

<section class="mx-auto py-9 lg:bg-gray-alabaster">
	<div class="container mx-auto flex flex-wrap lg:grid lg:grid-cols-2">
		<!-- Reviews -->
		<div class="flex flex-col text-center lg:flex-row lg:items-center lg:gap-x-11 lgmax:mx-auto">
			<img
				alt="Heureka hodnocení"
				class="mb-5 h-auto w-20 shrink-0 lg:w-36 lgmax:mx-auto"
				src={heureka}
			/>

			<div class="flex flex-col gap-y-1 lg:text-left">
				<p data-testid="heureka" class="text-lg font-bold leading-[1.444] lg:w-full">
					99 % zákazníků by doporučilo<br />obchod svým známým
				</p>

				<p class="lg:w-full">3700+ recenzí</p>
			</div>
		</div>

		<!-- Heureka -->
		{#if data?.heureka != null && data?.heureka.length}
			<div class="container mx-auto hidden grid-cols-2 p-6 text-black lg:grid lg:gap-8">
				{#each data?.heureka as review}
					<a
						href="https://obchody.heureka.cz/scootland-cz/recenze/"
						rel="noreferrer"
						target="_blank"
						title="heureka recenze"
						class="flex flex-col border border-gray-mercury bg-white p-8 shadow-sm transition-shadow hover:shadow-md"
					>
						<p class="mb-8 w-full grow">{review.summary}</p>
						{#if review?.total_rating}
							<div class="mt-auto flex w-full flex-wrap gap-x-0.5">
								{#each { length: 5 } as _, i}
									<div>
										<Star
											class={cn(
												'w-5',
												i < Math.round(review?.total_rating) ? 'text-yellow' : 'text-gray-mercury'
											)}
										/>
									</div>
								{/each}
								<p class="mt-2">Ověřený zákazník</p>
							</div>
						{/if}
					</a>
				{/each}
			</div>
		{/if}
	</div>
</section>

<section class="content flex flex-col gap-y-14 text-black lg:gap-y-24 lg:py-24">
	<!-- Vse na jednom miste -->
	<div
		class="container mx-auto flex flex-col items-center px-2.5 lg:flex-row lg:px-5 lgmax:!max-w-screen-sm"
	>
		<img alt="" src={vyber_skutru} class="w-full" />
		<div
			class="flex flex-col items-center text-center lg:w-2/5 lg:flex-grow lg:items-start lg:pl-36 lg:text-left"
		>
			<Heading tag="h2" class="mb-4 text-2xl lg:text-3xl">Vše pro skútry</Heading>
			<p class="mb-6 text-base leading-loose text-gray-tundora lg:text-lg">
				Zvolte svůj skútr a stránka se vám plně přizpůsobí. <br><br> Objevte nabídku zboží i návody, technické údaje a zajímavosti. 
			</p>
			<a href="/skutry/" title="Vyberte výrobce a model skútru" rel="prefetch" class="border-b-2 border-yellow py-1 font-bold"
				>Zvolit si skútr</a
			>
		</div>
	</div>

	<!-- Nahradni dily skladem -->
	<div
		class="container mx-auto flex flex-col-reverse items-center px-2.5 lg:flex-row lg:px-5 lgmax:!max-w-screen-sm"
	>
		<div
			class="flex flex-col items-center text-center lg:w-2/5 lg:flex-grow lg:items-start lg:pr-36 lg:text-left"
		>
			<Heading tag="h2" class="mb-4 text-2xl lg:text-3xl">Náhradní díly skladem</Heading>
			<p class="mb-6 text-base leading-loose text-gray-tundora lg:text-lg">
				Díky vlastnímu skladu odesíláme většinu objednávek ihned. <br><br>  U dílů na objednání vždy víte dobu
				dodání předem.
			</p>
			<a href="/eshop/" title="Eshop scootland: přehledná nabídka dílů pro skútry" class="border-b-2 border-yellow py-1 font-bold">Tak se ukažte</a>
		</div>
		<img alt="" src={content_2} class="mb-8 w-full lg:mb-0" />
	</div>

	<!-- Návody a tipy -->
	<div
		class="container mx-auto flex flex-col items-center px-2.5 lg:grid lg:grid-cols-5 lg:flex-row lg:px-5 lgmax:!max-w-screen-sm"
	>
		<img alt="" class="mb-12 w-full lg:col-span-3 lg:mb-0" src={content_3} />
		<div
			class="mb-16 flex flex-col items-center text-center lg:col-span-2 lg:flex-grow lg:items-start lg:pl-36 lg:text-left"
		>
			<Heading tag="h2" class="mb-4 text-2xl lg:text-3xl">Návody a tipy</Heading>
			<p class="mb-6 text-base leading-loose text-gray-tundora lg:text-lg">
				Zvládněte sami základní údržbu, tuning i náročnější opravy.  <br><br> Sledujte náš Youtube kanál nebo
				blog a my Vám ukážeme, jak na to!
			</p>
			<a href="/scooter-tuning-jaknato/" title="Návody, tipy a doporučení pro servis skútru svépomocí" class="border-b-2 border-yellow py-1 font-bold"
				>Chci se podívat</a
			>
		</div>
	</div>
</section>

<BlogList
	homepage={true}
	blog={data.blog}
	heading="Novinky:"
	subheading="Pravidelné info o všem ze Scootlandu i světa skútrů na jednom místě."
/>

<OurAdvantages class="bg-white" />
